import React, { useState, useEffect, useRef } from 'react'
import styles from './ProductOverview.module.scss'
import Button from '../common/Button'
import loadable from '@loadable/component'
import { signUpClick } from '../../utils/tracker.js'

const AnimationComponent = loadable(() =>
  import('../animations/AnimationComponent.js')
)

const ProductOverview = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    // Initial check
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.tag}>Born in the browser</div>
          <h2>No installations required</h2>
          <p>
            Connected to your data. Delightful to share. Collaborate spatially
            without installing software.
          </p>
          <div style={{ marginTop: '1rem' }}>
            <Button
              href="https://app.atlas.co/login"
              onClick={signUpClick}
              className={'plausible-event-name=Signup'}
            >
              Sign up
            </Button>

            <Button href="/contact" variant="secondary">
              Book a demo
            </Button>
          </div>
        </div>
        <div className={`${styles.gridLayout} ${styles.firstRow}`}>
          <div className={styles.gridItem}>
            <AnimationComponent selector="uploading" />
          </div>
          <div className={styles.gridItem}>
            <AnimationComponent selector="locations" />
          </div>
        </div>
        <div className={`${styles.gridLayout} ${styles.secondRow}`}>
          <div
            className={`${styles.gridItem} ${!isMobile &&
              styles.large} ${isMobile && styles.small}`}
          >
            <video
              src="https://cdn.atlas.co/landing-pages/Home-screen.mp4"
              loop
              autoPlay
              muted
              playsInline
              playsinline="true"
              webkit-playsinline="true"
              x5-playsinline="true"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 0,
                display: 'block',
                objectFit: 'cover',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                objectPosition: '50% 50%'
              }}
            ></video>
          </div>
          <div className={`${styles.gridItem} ${styles.large}`}>
            <AnimationComponent selector="share" />
          </div>
        </div>
        {/* <div className={styles.header}>
          <h2>
            The Most Powerful GIS <br /> Software in the Browser
          </h2>
          <p>
            Connected to your data. Delightful to share. Atlas is how teams work
            with geospatial data and share their results.
          </p>
          <div style={{ marginTop: '1rem' }}>
            <Button href="https://app.atlas.co/login">Start for free</Button>

            <Button href="/contact" variant="secondary">
              Book a demo
            </Button>
          </div>
        </div> */}
        {/* <div className={styles.grid}>
          <div className={styles.tabContainer}>
            <div>
              {tabs.map((tab, index) => (
                <div
                  className={`${styles.tab} ${activeTab === index + 1 &&
                    styles.nextActive}`}
                >
                  <div
                    className={`${styles.tabHeader} ${activeTab === index &&
                      styles.active}`}
                    onClick={() => activeTab !== index && handleTabClick(index)}
                  >
                    <h3>{tab.title}</h3>
                  </div>
                  <div
                    className={`${styles.tabContent} ${activeTab === index &&
                      styles.active}`}
                  >
                    {tab.content}
                  </div>
                  {activeTab === index && (
                    <div
                      className={styles.bottomBorder}
                      style={{ width: borderWidth }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img
              src={`/images/product-overview-0${activeTab + 1}.svg`}
              alt=""
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default ProductOverview
