import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'
import Fade from 'react-reveal/Fade'
import { PlayIcon } from '@heroicons/react/20/solid'
import CursorIcon from './CursorIcon.js'
import { signUpClick } from '../../utils/tracker.js'

const PageHeader = () => {
  const [open, setOpen] = useState(false)
  const animatedDivRef = useRef(null)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    // Initial check
    handleResize()

    // Attach the event listeners
    window.addEventListener('resize', handleResize)

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const videoRef = useRef()

  const handleFullscreenClick = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen()
    } else if (videoRef.current.mozRequestFullScreen) {
      // Firefox
      videoRef.current.mozRequestFullScreen()
    } else if (videoRef.current.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      videoRef.current.webkitRequestFullscreen()
    } else if (videoRef.current.msRequestFullscreen) {
      // IE/Edge
      videoRef.current.msRequestFullscreen()
    } else if (videoRef.current.webkitEnterFullscreen) {
      // iOS Safari
      videoRef.current.webkitEnterFullscreen()
    }
  }

  return (
    <>
      {open && (
        <Modal
          opened={open}
          onClose={setOpen}
          size="100%"
          withCloseButton={false}
          radius="lg"
        >
          <video
            src="https://cdn.atlas.co/landing-pages/Main-hero.mp4"
            loop
            autoPlay
            muted
            playsinline
            controls
            style={{
              cursor: 'auto',
              width: '100%',
              height: '100%',
              borderRadius: 0,
              display: 'block',
              objectFit: 'cover',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              objectPosition: '50% 50%'
            }}
          ></video>
        </Modal>
      )}
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <a
              href="https://www.producthunt.com/posts/atlas-co-1?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-atlas&#0045;co&#0045;1"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=460946&theme=light&period=daily"
                alt="Atlas&#0046;co - GIS&#0032;and&#0032;maps&#0032;in&#0032;the&#0032;browser | Product Hunt"
                style={{ width: '250px', height: '54px' }}
                width="250"
                height="54"
              />
            </a>
            {/* <span className={styles.tag}>Announcing our Public Beta</span> */}
            <Fade up duration={1000} delay={0} distance="30px" cascade>
              <h1 className={styles.title}>
                Collaborative GIS
                <br />
                in the browser
              </h1>
            </Fade>
            <div className={styles.descriptionContainer}>
              <div>
                <p>
                  Atlas is how teams make maps and perform geospatial analysis
                  together. Create, collaborate, share — all under one roof.
                </p>
                <div style={{ marginTop: '2rem' }}>
                  <Button
                    href="https://app.atlas.co/login"
                    size="xl"
                    onClick={signUpClick}
                    className={'plausible-event-name=Signup'}
                  >
                    Sign up -{' '}
                    <span
                      style={{
                        fontWeight: 300,
                        fontSize: '0.75em',
                        marginTop: '-1rem'
                      }}
                    >
                      it's free
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.cursorContainer}>
            <CursorIcon />
            <div ref={animatedDivRef} className={styles.cursor}>
              Elena
            </div>
          </div>
          <div className={styles.cursorContainer2}>
            <div ref={animatedDivRef} className={styles.cursor2}>
              Jasper
            </div>
            <CursorIcon />
          </div>
          <div className={styles.cursorContainer3}>
            <div ref={animatedDivRef} className={styles.cursor3}>
              Noelle
            </div>
            <CursorIcon />
          </div>
          <div
            className={styles.heroPromo}
            onClick={() =>
              !isMobile ? setOpen(true) : handleFullscreenClick()
            }
          >
            <video
              src="https://cdn.atlas.co/landing-pages/Main-hero.mp4"
              loop
              autoPlay
              muted
              playsInline
              playsinline="true"
              webkit-playsinline="true"
              x5-playsinline="true"
              ref={videoRef}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 0,
                display: 'block',
                objectFit: 'cover',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                objectPosition: '50% 50%'
              }}
            ></video>
            <div className={styles.playButton}>
              <div className={styles.playButtonInner}>
                <PlayIcon color="#fff" width={60} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
